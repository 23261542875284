
import { Component, Prop } from "vue-property-decorator";

import { BaseForm } from "./BaseForm";

@Component({})
export default class ConfirmSessionForm extends BaseForm {
	@Prop()
	public state!: string;

	public labels: Record<string, string> = {
		password: "Password",
	};

	public model: Record<string, any> = {
		password: null,
	};

	get username() {
		return this.$store.state.user;
	}

	get signoutUrl() {
		return this.$url(process.env.VUE_APP_SIGNOUT_URL as string, { state: this.state });
	}

	get identity() {
		return this.$store.state.identity;
	}

	public async send() {
		const response = await this.$api.post(this.action, { password: this.model.password });

		if (!response.ok) {
			return this.handleError(response);
		}

		this.$emit("done", response.body);
	}
}
