
import { Component, Vue, Prop } from "vue-property-decorator";

import ConfirmSessionForm from "./../components/forms/ConfirmSessionForm.vue";

@Component({
	components: {
		ConfirmSessionForm,
	},
})
export default class ConfirmSession extends Vue {
	@Prop({ default: () => ({}) })
	public query!: Record<string, any>;

	public returnUrl: string = process.env.VUE_APP_HOME_URL as string;

	public action = this.$url(process.env.VUE_APP_CONFIRM_SESSION_ACTION as string, this.query);

	created() {
		if (this.query.continue !== undefined) {
			this.returnUrl = this.query.continue;
		}

		console.log("Return url", this.returnUrl);

		console.log("Return url is internal? ", this.$isInternalUrl(this.returnUrl));
	}

	onConfirmDone({ data }: any) {
		return this.$redirect(data.next ?? this.returnUrl, true);
	}
}
